
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChartLegendComponent extends Vue {
  @Prop({ type: Array, required: false, default: [] }) legendSignals?: any;
  @Prop({ type: String, required: false, default: [] }) type?: any;
  @Prop({ type: Boolean, required: false, default: true }) isWellSignal?: any;

  handleClick(index, name) {
    this.$emit('select-signal-on-legend', { name, index, type: this.type });
  }

  get sortedSignals() {
    return this.legendSignals.sort((a, b) => a.name.localeCompare(b.name));
  }

  getCurrentSignalNameForLegend(signal_name) {
    if (signal_name == 'gas_rate') {
      return 'Gas Rate';
    }
    if (signal_name == 'oil_rate') {
      return 'Oil Rate';
    }
    if (signal_name == 'water_rate') {
      return 'Water Rate';
    }
    if (signal_name == 'boe_rate') {
      return 'Boe Rate';
    }
    if (signal_name == 'water_rate_fc') {
      return 'Water Rate FC';
    }
    if (signal_name == 'water_rate_source') {
      return 'Water Rate Source';
    }
    if (signal_name == 'gas_rate_fc') {
      return 'Gas Rate FC';
    }
    if (signal_name == 'oil_rate_fc') {
      return 'Oil Rate FC';
    }
    if (signal_name == 'gas_rate_source') {
      return 'Gas Rate Source';
    }
    if (signal_name == 'oil_rate_source') {
      return 'Oil Rate Source';
    }

    if (signal_name == 'water_rate_allocated') {
      return 'Water Rate (Allocated)';
    }

    if (signal_name == 'water_rate_projected') {
      return 'Water Rate (Projected)';
    }

    if (signal_name == 'oil_rate_allocated') {
      return 'Oil Rate (Allocated)';
    }

    if (signal_name == 'oil_rate_projected') {
      return 'Oil Rate (Projected)';
    }
    return signal_name;
  }
}
